<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'Base'
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

// RESPONSIVE XS
@media #{map-get($display-breakpoints, 'xs-only')} {
  .xs-absolute {
    position: absolute;
  }

  .d-xs-block {
    display: block;
  }
}

::v-deep {
  .v-btn-right-bottom {
    bottom: 0;
    right: 0;
    position: fixed;
    margin: 0 40px 36px 0;
  }
}

</style>
